import { template as template_93f7c6d72af04692bc31f54c00b71c0f } from "@ember/template-compiler";
const FKControlMenuContainer = template_93f7c6d72af04692bc31f54c00b71c0f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
