import { template as template_9418f3e20f5041359ef5b5e8663d37ea } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_9418f3e20f5041359ef5b5e8663d37ea(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
