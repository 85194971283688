import { template as template_07056c176a454720a8f7ed478f04bee6 } from "@ember/template-compiler";
const FKText = template_07056c176a454720a8f7ed478f04bee6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
