import { template as template_87f9fcf056a2487a98c9996a7dd278f6 } from "@ember/template-compiler";
const FKLabel = template_87f9fcf056a2487a98c9996a7dd278f6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
