import { template as template_db258394d84e4e7ea620ee71e19b2349 } from "@ember/template-compiler";
const SidebarSectionMessage = template_db258394d84e4e7ea620ee71e19b2349(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
